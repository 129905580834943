import { Alert, AlertIcon, VStack } from "@chakra-ui/react";
import { Loader } from "../components/loading/Loader";
import useHasPortfolioAccess from "../components/portfolio-dashboard/data/useHasPortfolioAccess";
import PortfolioDashboardPage from "./dashboard";
import PropertiesPage from "./property";

const IndexPage = () => {
  return <PropertiesPage />;
};

export default IndexPage;
